import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import moment from 'moment';

export default {
  namespaced: true,
  state: {
    listaTablaModal: [],
    estado: true,
    filter: null,
    totalItems: 0,
    currentPage: 1,
    perPage: 15,
    isBusy: false,
    disponible: false,
    errors: [],
    indexCotizacionAnterior: -1,
    mensajeError: null,
    delayTimeout: null,
    cotizacion: null,
    cotizacionId: null,
    numeroCotizacion: null,
  },
  getters: {
    getField,
    getCotizacionId: (state) => state.cotizacionId,
    getNumeroCotizacion: (state) => state.numeroCotizacion,
  },
  mutations: {
    updateField,
    setListaTablaModal: (state, data) => { state.listaTablaModal = data; },
    setEstado: (state, data) => { state.estado = data; },
    setFilter: (state, data) => { state.filter = data; },
    setTotalItems: (state, data) => { state.totalItems = data; },
    setTotalCurrentPage: (state, data) => { state.currentPage = data; },
    setPerPage: (state, data) => { state.perPage = data; },
    setIsBusy: (state, data) => { state.isBusy = data; },
    setDisponible: (state, data) => { state.disponible = data; },
    setErrors: (state, data) => { state.errors = data; },
    setIndexCotizacionAnterior: (state, data) => { state.indexCotizacionAnterior = data; },
    setMensajeError: (state, data) => { state.mensajeError = data; },
    setDelayTimeOut: (state, data) => { state.delayTimeout = data; },
    setCotizacion: (state, data) => { state.cotizacion = data; },
    setCotizacionId: (state, data) => { state.cotizacionId = data; },
    setNumeroCotizacion: (state, data) => { state.numeroCotizacion = data; },
  },
  actions: {
    async getDataCotizacion({ state, commit }) {
      try {
        const params = {
          page: state.currentPage,
          per_page: state.perPage,
          estado: state.estado,
          filter: state.filter,
        };
        const RESPONSE = await axios.get('/clinic/quotation/search', { params });
        commit('setListaTablaModal', RESPONSE.data.data.cotizaciones);
        commit('setTotalItems', RESPONSE.data.data.total);
        return true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        return false;
      }
    },
    resetModalFiltrarContizacion({ commit }) {
      commit('setListaTablaModal', []);
      commit('setEstado', true);
      commit('setFilter', null);
      commit('setTotalItems', 0);
      commit('setTotalCurrentPage', 1);
      commit('setPerPage', 15);
      commit('setIsBusy', false);
      commit('setDisponible', false);
      commit('setErrors', []);
      commit('setIndexCotizacionAnterior', -1);
      commit('setMensajeError', null);
      commit('setDelayTimeOut', null);
      commit('setCotizacion', null);
      commit('setCotizacionId', null);
      commit('setNumeroCotizacion', null);
    },
    getDataService({ state, commit, rootGetters }, dto) {
      const INDEX = dto.index;
      const params = {
        id: dto.id,
        fecha: moment(rootGetters['formFactura/getDatePicker']).format('YYYY-MM-DD'),
        modalidad_id: dto.modalidad_id,
        sucursal_id: dto.sucursal_id,
      };
      commit('setMensajeError', null);
      axios.get('/clinic/quotation/validar_stock', { params })
        .then((response) => {
          const COTIZACION = response.data.data;
          commit('setCotizacion', COTIZACION);
        })
        .catch((error) => {
          if (error.response.data.error === 7 && error.response.data.data.length === 0) {
            commit('setCotizacion', null);
            commit('setMensajeError', error.response.data.message);
          }
          util.showNotify(error.response.data.message, 'error');
        }).finally(() => {
          state.listaTablaModal[INDEX].hayStock = true;
          if (state.indexCotizacionAnterior >= 0 && state.indexCotizacionAnterior !== INDEX) {
            state.listaTablaModal[state.indexCotizacionAnterior].hayStock = false;
          }
          state.indexCotizacionAnterior = INDEX;
        });
    },
  },
};
