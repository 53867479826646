import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callValidateTurno({ rootState }, dto) {
  const RESERVA_ID = rootState.modalReserva.reservaId ? rootState.modalReserva.reservaId : null;
  const REQUEST = {
    turno_detalles: dto.turno_detalles,
    reserva_id: RESERVA_ID,
  };
  try {
    await axios.post('/clinic/invoicing/validate_turno', REQUEST);
    return true;
  } catch (error) {
    const FALLO_VALIDACION = 422;
    if (error.response.status !== FALLO_VALIDACION) {
      util.showNotify(error.response.data.message, 'error');
    } else {
      Object.entries(error.response.data.data).forEach(([, mensajes]) => {
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      });
    }
    return false;
  }
}
