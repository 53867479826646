import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  namespaced: true,
  state: {
    medicos: [],
    turnos: null,
    cantidadCitas: 0,
    fecha: moment().locale('es'),
    medicoActual: null,
  },
  getters: {
    medicosCount: (state) => state.medicos.length,
    turnos: (state) => state.turnos,
    cantidadCitas: (state) => state.cantidadCitas,
    fecha: (state) => state.fecha,
  },
  mutations: {
    setMedicos(state, medicos) { state.medicos = medicos; },
    setLoad(state, load) { state.load = load; },
    setTurnos(state, turnos) { state.turnos = turnos; },
    setCantidadCitas(state, cantidadCitas) { state.cantidadCitas = cantidadCitas; },
    setFecha(state, fecha) { state.fecha = fecha; },
    setMedicoActual(state, medico) { state.medicoActual = medico; },
  },
  actions: {
    setMedicoActual({ commit }, medico) {
      commit('setMedicoActual', medico);
    },
    async getMedicos({ commit, state }) {
      try {
        const RESPONSE = await axios.get('clinic/medicos/calendar');
        commit('setMedicos', RESPONSE.data.data.data);
        if (state.medicoActual === null && state.medicos.length > 0) {
          commit('setMedicoActual', state.medicos[0]);
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        commit('setLoad', true);
      }
    },
    async getTurno({ commit, state }, medicoId) {
      commit('setTurnos', null);
      try {
        const REQUEST = {
          fecha: moment(state.fecha).format('YYYY-MM-DD'),
          medico_id: medicoId,
        };
        const RESPONSE = await axios.get('clinic/medicos/turno', { params: REQUEST });
        const DATATURNO = RESPONSE.data.data.turnos;
        commit('setTurnos', DATATURNO);
        commit('setCantidadCitas', RESPONSE.data.data.cantidad_citas);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async pdfResumenDelDia({ state }, medico) {
      try {
        const YEAR = moment().locale('es').format('YYYY');
        const MES = moment().locale('es').format('MMMM');
        const DIA_LITERAL = moment().locale('es').format('dddd');
        const DIA = moment().format('DD');
        const FECHALITERAL = `${DIA_LITERAL} ${DIA} de ${MES} ${YEAR}`;

        const REQUEST = {
          fecha: moment(state.fecha).format('YYYY-MM-DD'),
          medico_id: medico.id,
          fecha_literal: FECHALITERAL,
        };

        const RESPONSE = await axios.get('clinic/medico/resumen-dia', { params: REQUEST, responseType: 'blob' });
        const blob = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const downloadUrl = URL.createObjectURL(blob);
        window.open(downloadUrl, '_blank');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
};
