import { FORMAS_PAGO } from '@/utils/globalConventions';
import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import callCreateApi from './apis/create';
import callStoreApi from './apis/store';
import saveFacturaVenta from './apis/saveFacturaVenta';
import callEditApi from './apis/edit';
import callUpdateApi from './apis/update';
import callValidateTurno from './apis/validateTurno';

export default {
  namespaced: true,
  state: {
    modoEdicion: false,
    showCortinaInformativa: false,
    pacienteToShow: {
      anticipo: null,
      edad: '',
      ultima: '',
      cobertura: '',
      tratamiento: null,
    },
    sucursal: null,
    puntoVentas: [],
    conexionSiat: false,
    correlativo: null,
    hasEnabledLaboratory: false,
  },
  getters: {
    getField,
    getModoEdicion: (state) => state.modoEdicion,
    getShowCortinaInformativa: (state) => state.showCortinaInformativa,
    getPacienteToShow: (state) => state.pacienteToShow,
    getSucursal: (state) => state.sucursal,
    getPuntoVentas: (state) => state.puntoVentas,
    getCorrelativo: (state) => state.correlativo,
  },
  mutations: {
    updateField,
    setModoEdicion: (state, data) => { state.modoEdicion = data; },
    setShowCortinaInformativa: (state, data) => { state.showCortinaInformativa = data; },
    setPacienteToShow: (state, data) => { state.pacienteToShow = data; },
    setSucursal: (state, data) => { state.sucursal = data; },
    setPuntoVentas: (state, data) => { state.puntoVentas = data; },
    setConexionSiat: (state, data) => { state.conexionSiat = data; },
    setCorrelativo: (state, data) => { state.correlativo = data; },
    setHasEnabledLaboratory: (state, data) => { state.hasEnabledLaboratory = data; },
  },
  actions: {
    callCreateApi,
    saveFacturaVenta,
    callStoreApi,
    callEditApi,
    callUpdateApi,
    getDataFacturaElectronica({ getters, rootState, rootGetters }) {
      let siatPuntoVenta = null;
      getters.getPuntoVentas.forEach((ele) => {
        if (ele.siat_sucursal_id === getters.getSucursal.siat_sucursal_id) {
          siatPuntoVenta = ele;
        }
      });

      const servicios = [];
      const tipoCambio = rootState.formFactura.comboMoneda.find((el) => el.is_principal === 0).tc;
      const detalleServicios = rootGetters['tableAndAddService/getListaTabla'];
      detalleServicios.forEach((el) => {
        // los servicios no estan trabajan con la funcionalidad de descuento
        const precioUnitario = rootState.formFactura.monedaSelected.is_principal ? el.precio_unitario : el.precio_unitario * tipoCambio;
        const subTotalSeguro = parseFloat(el.seguro) * parseFloat(el.cantidad);
        const descuento = rootState.formFactura.monedaSelected.is_principal ? el.descuento : el.descuento * tipoCambio;
        const total = parseFloat(el.total).toFixed(2);
        const subTotal = rootState.formFactura.monedaSelected.is_principal ? total : total * tipoCambio;
        const servicio = {
          siat_actividadeconomica_id: el.siat_actividad_economica_id,
          siat_productoservicio_id: el.siat_tipo_producto_service_id,
          cod_producto: el.numero,
          descripcion: el.servicio,
          cantidad: el.cantidad,
          siat_unidadmedida_id: el.siat_unidad_medida_id,
          precio_unitario: parseFloat(precioUnitario).toFixed(2),
          monto_descuento: descuento,
          sub_total: parseFloat(subTotal).toFixed(2) - descuento - subTotalSeguro,
          numero_serie: '',
          numero_imei: '',
        };
        servicios.push(servicio);
      });
      const descuentoTotal = rootState.detalleFactura.descuento;
      const descuentoAdicional = parseFloat(rootState.formFactura.monedaSelected.is_principal ? descuentoTotal : descuentoTotal * tipoCambio).toFixed(2);
      const totalVenta = rootGetters['detalleFactura/getTotal'];
      const totalSus = parseFloat(rootState.formFactura.monedaSelected.is_principal ? totalVenta / tipoCambio : totalVenta).toFixed(2);
      const totalBs = parseFloat(rootState.formFactura.monedaSelected.is_principal ? totalVenta : totalVenta * tipoCambio).toFixed(2);
      const formaPago = rootGetters['formaPagoModal/getFormaPago'];
      const medioPago = rootGetters['formaPagoModal/getMedioPago'];
      let medioPagoSiat = medioPago ? medioPago.siat_tipo_medio_pago_id : '';
      if (formaPago.tipo_forma_pago_id === FORMAS_PAGO.CREDITO) {
        // venta a credito
        medioPagoSiat = rootState.formaPagoModal.medioPagoCredito.siat_tipo_medio_pago_id;
      }
      if ((rootState.formaPagoModal.checkCXP) && (rootState.formaPagoModal.isAjuste)) {
        // con ajuste total a la venta
        medioPagoSiat = rootState.formaPagoModal.medioPagoAjusteAnticipo.siat_tipo_medio_pago_id;
      }
      const data = {
        cabecera: {
          nit_emisor: rootGetters['main/getCompany'].nit,
          razon_social_emisor: rootGetters['main/getCompany'].business_name,
          municipio: '0',
          telefono: rootGetters['main/getCompany'].celular_representante,
          numero_factura: rootState.formFactura.nroFactura,
          siat_sucursal_id: getters.getSucursal.siat_sucursal_id,
          direccion: '0',
          siat_puntoventa_id: siatPuntoVenta ? siatPuntoVenta.siat_tipo_punto_venta_id : '',
          fecha_emision: moment(rootGetters['formFactura/getDatePicker']).format('YYYY-MM-DD H:m:s'),
          razon_social: rootState.formFactura.razonSocial,
          siat_tipodocidentidad_id: rootState.formFactura.indentificacionSelected.tipo_documento_id_siat,
          nro_documento: rootState.formFactura.nit,
          complemento: rootState.formFactura.complemento,
          codigo_cliente: rootState.formFactura.pacienteSelected.numero,
          siat_metodopago_id: medioPagoSiat,
          nro_tarjeta: rootState.formaPagoModal.numeroTarjeta ? rootState.formaPagoModal.numeroTarjeta : 0,
          monto_total: totalBs,
          monto_total_sujetoiva: totalBs,
          siat_tipomoneda_id: rootState.formFactura.monedaSelected.is_principal === 1 ? rootState.formFactura.monedaSelected.siat_moneda_principal_id : rootState.formFactura.monedaSelected.siat_moneda_secundaria_id,
          tipo_cambio: rootState.formFactura.monedaSelected.is_principal ? 1 : rootState.formFactura.monedaSelected.tc,
          monto_tatal_moneda: (rootState.formFactura.monedaSelected.is_principal === 1 ? totalVenta : totalSus),
          monto_giftcard: 0,
          descuento_adicional: descuentoAdicional,
          codigo_exception: 1, // por defecto 1,
          siat_leyenda_id: rootState.formFactura.siatLeyendaSelected ? rootState.formFactura.siatLeyendaSelected.id : '',
          siat_nombre_leyenda: rootState.formFactura.siatLeyendaSelected ? rootState.formFactura.siatLeyendaSelected.leyenda : '',
          usuario: rootState.main.user.name,
          siat_docsector_id: rootState.addServiceModal.documentoSectorId,
          cafc: rootState.formFactura.cafc,
          cuf: '',
        },
        detalle: servicios,
      };
      return data;
    },
    getDataMedicoFacturaElectronica({ /* getters, */ rootState, rootGetters }) {
      const Medico = rootGetters['formFactura/getMedicoSelected'];
      const CONFIG_FACTURA_ELECTRONICA_MEDICO = rootState.formFactura.configuracion_factura_electronica;
      const SIAT_MEDIOS_PAGO_MEDICO = rootState.formFactura.siatMedioPagoMedico;
      const SIAT_TIPOS_DOCS_MEDICO = rootState.formFactura.siatTipoDocumentoMedico;
      const SIAT_TIPO_DOC = SIAT_TIPOS_DOCS_MEDICO.find((el) => el.siat_nombre_tipo_documento === 'NIT') ?? null;
      const SIAT_TIPO_MEDIO_PAGO = SIAT_MEDIOS_PAGO_MEDICO;

      const siatPuntoVentaId = Medico.siat_punto_venta_id ?? '';
      const servicios = [];
      const tipoCambio = rootState.formFactura.comboMoneda.find((el) => el.is_principal === 0).tc;
      const detalleServicios = rootGetters['tableAndAddService/getListaTabla'];
      detalleServicios.forEach((el) => {
        // los servicios no estan trabajan con la funcionalidad de descuento
        const precioUnitario = rootState.formFactura.monedaSelected.is_principal ? el.precio_unitario : el.precio_unitario * tipoCambio;
        const subTotalSeguro = parseFloat(el.seguro) * parseFloat(el.cantidad);
        const descuento = rootState.formFactura.monedaSelected.is_principal ? el.descuento : el.descuento * tipoCambio;
        const total = parseFloat(el.total).toFixed(2);
        const subTotal = rootState.formFactura.monedaSelected.is_principal ? total : total * tipoCambio;
        const servicio = {
          siat_actividadeconomica_id: Medico.siat_actividad_economica_id,
          siat_productoservicio_id: Medico.siat_producto_servicio_id,
          cod_producto: el.numero,
          descripcion: el.servicio,
          cantidad: el.cantidad,
          siat_unidadmedida_id: el.siat_unidad_medida_id,
          precio_unitario: parseFloat(precioUnitario).toFixed(2),
          monto_descuento: descuento,
          sub_total: parseFloat(subTotal).toFixed(2) - descuento - subTotalSeguro,
          numero_serie: '',
          numero_imei: '',
        };
        servicios.push(servicio);
      });
      const descuentoTotal = rootState.detalleFactura.descuento;
      const descuentoAdicional = parseFloat(rootState.formFactura.monedaSelected.is_principal ? descuentoTotal : descuentoTotal * tipoCambio).toFixed(2);
      const totalVenta = rootGetters['detalleFactura/getTotal'];
      const totalSus = parseFloat(rootState.formFactura.monedaSelected.is_principal ? totalVenta / tipoCambio : totalVenta).toFixed(2);
      const totalBs = parseFloat(rootState.formFactura.monedaSelected.is_principal ? totalVenta : totalVenta * tipoCambio).toFixed(2);

      let medioPagoSiat = null;
      const { caja } = rootState.formaPagoFacturaTercero;
      const { banco } = rootState.formaPagoFacturaTercero;
      const { tarjeta } = rootState.formaPagoFacturaTercero;
      if (caja > 0 && banco === 0 && tarjeta === 0) {
        medioPagoSiat = SIAT_TIPO_MEDIO_PAGO.find((el) => el.tipo_id === 1 /* 1: Efectivo, 2: Banco, 3: Tarjeta */);
      }
      if (caja === 0 && banco > 0 && tarjeta === 0) {
        medioPagoSiat = SIAT_TIPO_MEDIO_PAGO.find((el) => el.tipo_id === 2);
      }
      if (caja === 0 && banco === 0 && tarjeta > 0) {
        medioPagoSiat = SIAT_TIPO_MEDIO_PAGO.find((el) => el.tipo_id === 3 || el.tipo_id === 4);
      }
      if (
        (caja > 0 && banco > 0 && tarjeta === 0)
        || (caja === 0 && banco > 0 && tarjeta > 0)
        || (caja > 0 && banco === 0 && tarjeta > 0)
        || (caja > 0 && banco > 0 && tarjeta > 0)
      ) {
        medioPagoSiat = SIAT_TIPO_MEDIO_PAGO.find((el) => el.tipo_id === 1);
      }
      const data = {
        cabecera: {
          nit_emisor: Medico.nit,
          razon_social_emisor: Medico.razon_social,
          municipio: '0',
          telefono: Medico.telefono,
          numero_factura: rootState.formFactura.nroFactura,
          siat_sucursal_id: Medico.siat_sucursal_id,
          direccion: '0',
          siat_puntoventa_id: siatPuntoVentaId,
          fecha_emision: moment(rootGetters['formFactura/getDatePicker']).format('YYYY-MM-DD H:m:s'),
          razon_social: rootState.formFactura.razonSocial,
          siat_tipodocidentidad_id: SIAT_TIPO_DOC ? SIAT_TIPO_DOC.siat_tipo_documento_id : null,
          nro_documento: rootState.formFactura.nit,
          complemento: rootState.formFactura.complemento,
          codigo_cliente: rootState.formFactura.pacienteSelected.numero,
          siat_metodopago_id: medioPagoSiat ? medioPagoSiat.siat_medio_pago_id : null,
          nro_tarjeta: 0,
          monto_total: totalBs,
          monto_total_sujetoiva: totalBs,
          siat_tipomoneda_id: rootState.formFactura.monedaSelected.is_principal === 1 ? Medico.siat_moneda_principal_id : Medico.siat_moneda_secundaria_id,
          tipo_cambio: rootState.formFactura.monedaSelected.is_principal ? 1 : rootState.formFactura.monedaSelected.tc,
          monto_tatal_moneda: (rootState.formFactura.monedaSelected.is_principal === 1 ? totalVenta : totalSus),
          monto_giftcard: 0,
          descuento_adicional: descuentoAdicional,
          codigo_exception: 1, // por defecto 1,
          siat_leyenda_id: rootState.formFactura.siatLeyendaSelected ? rootState.formFactura.siatLeyendaSelected.id : '',
          siat_nombre_leyenda: rootState.formFactura.siatLeyendaSelected ? rootState.formFactura.siatLeyendaSelected.leyenda : '',
          usuario: rootState.main.user.name,
          siat_docsector_id: CONFIG_FACTURA_ELECTRONICA_MEDICO.siat_documento_sector_id,
          cafc: rootState.formFactura.cafc,
          cuf: '',
        },
        detalle: servicios,
      };
      return data;
    },
    resetFacturacion({ commit }) {
      commit('setModoEdicion', false);
      commit('setShowCortinaInformativa', false);
      commit('setPacienteToShow', {
        anticipo: null,
        edad: '',
        ultima: '',
        cobertura: '',
        tratamiento: null,
      });
      commit('setSucursal', null);
      commit('setPuntoVentas', []);
      commit('setConexionSiat', false);
      commit('setHasEnabledLaboratory', false);
    },
    callValidateTurno,
  },
};
